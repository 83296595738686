class Global {

    httpOptions = {}; // for HTTP Headers

    // baseUrl = 'http://localhost:8000';
    baseUrl = 'https://nodis-api.azharmughal.com';
    // baseUrl = 'https://api.nodis.eu';
    // baseUrl = process.env.REACT_APP_API_BASE_URL;

    // // -----------Auth---------
    login = `${this.baseUrl}/api/login`;
    // register = `${this.baseUrl}/api/v1/auth/register`;
    // login = `${this.baseUrl}/api/v1/auth/login`;
    // verifyEmail = `${this.baseUrl}/api/v1/auth/verify-email`;
    // forgotPassword = `${this.baseUrl}/api/v1/auth/forgot-password`;
    // resetPassword = `${this.baseUrl}/api/v1/auth/reset-password`;

    // -----------Client---------
    feSettings = `${this.baseUrl}/api/nodis-settings`;
    courses = `${this.baseUrl}/api/courses`;
    course = `${this.baseUrl}/api/course`;
    cmsPost = `${this.baseUrl}/api/cms-post`;
    cmsPostBulk = `${this.baseUrl}/api/cms-posts-bulk`;
    partners = `${this.baseUrl}/api/partners`;
    banners = `${this.baseUrl}/api/banners`;
    blog = `${this.baseUrl}/api/blog`;
    contactUs = `${this.baseUrl}/api/meet-us`;


    // -----------Admin---------
    adminSettings = `${this.baseUrl}/api/settings`;

    cmsContent = `${this.baseUrl}/api/cms-content`;
    cmsPosts = `${this.baseUrl}/api/cms-posts`;
    postDetail = `${this.baseUrl}/api/post-detail`;
    postStatus = `${this.baseUrl}/api/post-status`;
    newPost = `${this.baseUrl}/api/new-post`;
    updatePost = `${this.baseUrl}/api/update-post`;

    blogPosts = `${this.baseUrl}/api/blog-n-news`;
    allPartners = `${this.baseUrl}/api/admin-partners`;
    allBanners = `${this.baseUrl}/api/admin-banners`;

    adminCourse = `${this.baseUrl}/api/admin-course`;
    adminCourses = `${this.baseUrl}/api/admin-courses`;
    newCourse = `${this.baseUrl}/api/new-course`;
    updateCourse = `${this.baseUrl}/api/update-course`;
    courseStatus = `${this.baseUrl}/api/course-status`;

}

export default new Global();
